Vue.component("add-to-wish-list-icon", {
  template: "#vue-add-to-wish-list-icon",
  props: {
    variationId: Number,
  },
  inject: {
    itemId: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isVariationInWishList() {
      return this.wishListIds.includes(this.currentVariationId);
    },
    currentVariationId() {
      return !(this.variationId === null || this.variationId === undefined)
        ? this.variationId
        : this.currentVariationVariationId;
    },
    currentVariationVariationId() {
      const currentVariation = this.$store.getters[`${this.itemId}/currentItemVariation`];

      if (currentVariation === null || currentVariation === undefined) return null;

      return currentVariation && currentVariation.variation && currentVariation.variation.id;
    },
    ...Vuex.mapState({
      wishListIds: (state) => state.wishList.wishListIds,
    }),
  },
  methods: {
    switchState() {
      if (this.isVariationInWishList) {
        this.removeFromWishList();
      } else {
        this.addToWishList();
      }
    },
    addToWishList() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store.dispatch("addToWishList", parseInt(this.variationId ?? this.currentVariationId)).then(
          (response) => {
            this.isLoading = false;

            CeresNotification.success(this.$translate("Der Artikel wurde der Wunschliste hinzugefügt.")).closeAfter(
              3000
            );
          },
          (error) => {
            this.isLoading = false;
          }
        );

        this.triggerAddToWishListEvent();
      }
    },
    removeFromWishList() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch("removeWishListItem", {
            id: parseInt(this.variationId ?? this.currentVariationId),
          })
          .then(
            (response) => {
              this.isLoading = false;

              CeresNotification.success(this.$translate("Der Artikel wurde von der Wunschliste entfernt.")).closeAfter(
                3000
              );
            },
            (error) => {
              this.isLoading = false;
            }
          );
      }
    },
    changeTooltipText() {
      const tooltipText = this.$translate(
        this.isVariationInWishList ? "Von Wunschliste entfernen" : "Zur Wunschliste hinzufügen"
      );

      $(".add-to-wish-list").attr("data-original-title", tooltipText).tooltip("hide").tooltip("setContent");
    },
    triggerAddToWishListEvent() {
      const variationId = this.variationId ?? this.currentVariationId;
      const item = itemListProducts ? itemListProducts.find((e) => e.id == variationId) : null;
      if (!item) {
        console.error(`Item with id ${variationId} not found`);
        return;
      }

      dataLayer.push({
        event: "addToWishListItemList",
        addToWishListItemList: {
          id: variationId,
          number: item.number,
          name: item.name,
          price: item.price,
          priceNet: item.priceNet,
          rrp: item.rrp,
          rrpNet: item.rrpNet,
          brand: item.brand,
          category: item.category,
          variant: item.variant,
          quantity: item.quantity,
          productLink: item.productLink,
          list: item.list,
        },
      });
    },
  },
  watch: {
    isVariationInWishList() {
      this.changeTooltipText();
    },
  },
});
