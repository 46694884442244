Vue.component("current-user-button", {
  template: "#current-user-button",
  props: {
    className: String,
    buttonClass: String,
    style: String,
  },
  data() {
    return {
      isLogin: App.templateType === "login",
    };
  },
  computed: {
    ...Vuex.mapGetters(["username", "isLoggedIn"]),
  },
  methods: {
    createLoginModal() {
      this.loadComponent("login-modal");
    },
    ...Vuex.mapActions(["loadComponent"]),
  },
});
