Vue.component("add-item-to-basket-overlay", {
  template: "#add-item-to-basket-overlay",
  props: {
    defaultTimeToClose: { type: Number, default: 15 },
  },
  data() {
    return {
      price: 0,
      basketItem: null,
      countAdditionalBasketItems: 0,
    };
  },
  computed: {
    ...Vuex.mapState({
      basketItems: (state) => state.basket.items,
      isBasketItemQuantityUpdate: (state) => state.basket.isBasketItemQuantityUpdate,
    }),

    isLastBasketEntrySet() {
      return !(this.basketItem === null || this.basketItem === undefined);
    },
    variation() {
      if (this.basketItem) {
        return this.basketItem.variation ? this.basketItem.variation.data : null;
      }

      return null;
    },
    itemName() {
      if (this.isLastBasketEntrySet) {
        return this.$options.filters.itemName(this.variation);
      }

      return "";
    },
    imageUrl() {
      if (this.isLastBasketEntrySet) {
        const images = this.$options.filters.itemImages(this.variation.images, "urlPreview");

        return this.$options.filters.itemImage(images);
      }

      return "";
    },
    imageAlternativeText() {
      if (this.isLastBasketEntrySet) {
        const images = this.$options.filters.itemImages(this.variation.images, "urlPreview");

        return this.$options.filters.itemImageAlternativeText(images);
      }

      return "";
    },
    urls() {
      return {
        basket: App.urls.basket,
        checkout: App.urls.checkout,
      };
    },
    shownOrderProperties() {
      return this.basketItem.basketItemOrderParams.filter((property) => {
        return !!this.variation.properties.find((prop) => prop.propertyId == property.propertyId);
      });
    },
  },
  methods: {
    showItem(basketItem, countAdditionalBasketItems) {
      CeresNotification.success(this.$translate("Ceres::Template.singleItemAdded")).closeAfter(3000);
    },
  },
});
