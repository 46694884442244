Vue.component("latest-news-posts", {
  template: "#vue-latest-news-posts",
  data: () => ({
    posts: null,
    loading: false,
  }),
  mounted() {
    this.index();
  },
  methods: {
    index() {
      this.loading = true;
      this.posts = null;

      $.get({
        url: "https://info.konsolenkost.de/cache/get.php?source=3&callback=?",
        data: null,
        dataType: "jsonp",
      }).done((response) => {
        this.posts = response;
        this.loading = false;
      });
    },
  },
});
