import Cookie from "js-cookie";
const { get: getCookie, set: setCookie } = Cookie;

Vue.component("header-app-store-banner", {
  template: "#header-app-store-banner",
  data: function data() {
    return {
      show: true,
      android: true,
      empty: false,
    };
  },
  created: function created() {
    this.toggleActive();
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.android = false;
    }
  },
  methods: {
    toggleActive: function toggleActive() {
      if (getCookie("appStoreHeader")) {
        this.show = false;
      }
    },
    deactivateBanner: function deactivateBanner() {
      var self = this;

      setCookie("appStoreHeader", true, { expires: 7 });
      this.show = false;
      setTimeout(() => self.handleOffset(), 100);
    },
    handleOffset: function handleOffset() {
      var vueApp = document.getElementById("vue-app");
      var bodyOffset = 0;
      var headerParent = document.querySelector("[data-header-offset]");

      for (var i = 0; i < headerParent.children.length; i++) {
        bodyOffset += headerParent.children[i].getBoundingClientRect().height;
      }

      vueApp.style.marginTop = bodyOffset + "px";
      vueApp.style.minHeight = "calc(100vh - " + bodyOffset + "px)";
    },
  },
});
