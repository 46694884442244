const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

Vue.component("subscribe-to-back-in-stock", {
  template: "#subscribe-to-back-in-stock",
  props: {
    klaviyoApiKey: String,
    customerEmail: String,
    variationId: String,
  },
  data() {
    return {
      isLoading: false,
      subscribed: false,
      emailRequested: false,
      emailValid: true,
      email: this.customerEmail ?? "",
    };
  },
  methods: {
    validate() {
      this.emailValid = emailRegex.test(this.email);
      return this.emailValid;
    },
    subscribe() {
      if (!this.email || !this.validate(this.email)) {
        this.emailRequested = true;
        return;
      }

      this.isLoading = true;
      $.ajax({
        type: "POST",
        url: "https://a.klaviyo.com/onsite/components/back-in-stock/subscribe",
        data: {
          a: this.klaviyoApiKey,
          email: this.email,
          variant: this.variationId,
          platform: "api",
        },
        success: () => {
          CeresNotification.success("Du hast Dich für den Kaufalarm angemeldet!").closeAfter(3000);
          this.isLoading = false;
          this.subscribed = true;
          this.emailRequested = false;
        },
        error: () => {
          CeresNotification.error(
            "Leider konnten wir Dich nicht für den Kaufalarm anmelden. Versuche es später noch einmal."
          ).closeAfter(3000);
          this.isLoading = false;
          this.emailRequested = false;
        },
      });
    },
  },
});
