import Cookie from "js-cookie";
const { get: getCookie, set: setCookie } = Cookie;

Vue.component("header-coupon-banner", {
  template: "#header-coupon-banner",
  data: () => ({
    show: false,
    couponCode: null,
    couponText: null,
  }),
  created() {
    this.index();
  },
  methods: {
    index() {
      this.couponCode = this.getParameterByName("couponCode");
      this.couponText = this.getParameterByName("couponText") || "Dein persönlicher Gutschein-Code:";
      this.couponCodeCookie = this.getParameterByName("couponCode", getCookie("couponNotification") || "");
      this.couponTextCookie = this.getParameterByName("couponText", getCookie("couponNotification") || "");

      if (!this.couponCode) {
        this.couponCode = this.couponCodeCookie;
        this.couponText = this.couponTextCookie || this.couponText;
      } else {
        setCookie(
          "couponNotification",
          `couponCode=${encodeURIComponent(this.couponCode)}&couponText=${encodeURIComponent(this.couponText)}`,
          { expires: 7 }
        );
      }

      if (this.couponCode) this.show = true;
    },
    getParameterByName(name, url = window.location.search.slice(1)) {
      const params = this.decodeURIParams(url);
      return params[name] ? params[name] : null;
    },
    decodeURIParams(params) {
      if (!params) return {};
      return JSON.parse('{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      });
    },
  },
});
