Vue.component("copy-button", {
  template: "#copy-button",
  props: {
    className: String,
    copyClassName: String,
    style: String,
    text: String,
    copyText: String,
    title: String,
    icon: String,
    copyMessage: String,
    clickEverything: Boolean,
    showCopyText: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.copyText).then(
        () => {
          CeresNotification.success(this.copyMessage || "Code wurde in die Zwischenablage kopiert").closeAfter(3000);
        },
        () => {
          CeresNotification.error("Leider konnte der Text nicht kopiert werden.").closeAfter(3000);
        }
      );
    },
  },
});
