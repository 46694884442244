Vue.component("add-to-wish-list", {
  template: "#add-to-wish-list",
  props: {
    variationId: Number,
  },
  inject: {
    itemId: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isVariationInWishList() {
      return this.wishListIds.includes(this.currentVariationId);
    },
    currentVariationId() {
      return !(this.variationId === null || this.variationId === undefined)
        ? this.variationId
        : this.currentVariationVariationId;
    },
    currentVariationVariationId() {
      const currentVariation = this.$store.getters[`${this.itemId}/currentItemVariation`];

      if (currentVariation === null || currentVariation === undefined) {
        return null;
      }

      return currentVariation && currentVariation.variation && currentVariation.variation.id;
    },
    ...Vuex.mapState({
      wishListIds: (state) => state.wishList.wishListIds,
    }),
  },
  methods: {
    switchState() {
      if (this.isVariationInWishList) {
        this.removeFromWishList();
      } else {
        this.addToWishList();
      }
    },
    addToWishList() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store.dispatch("addToWishList", parseInt(this.currentVariationId)).then(
          (response) => {
            this.isLoading = false;

            CeresNotification.success("Der Artikel wurde der Wunschliste hinzugefügt.").closeAfter(3000);
          },
          (error) => {
            this.isLoading = false;
          }
        );
      }
    },
    removeFromWishList() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store.dispatch("removeWishListItem", { id: parseInt(this.currentVariationId) }).then(
          (response) => {
            this.isLoading = false;

            CeresNotification.success("Der Artikel wurde von der Wunschliste entfernt.").closeAfter(3000);
          },
          (error) => {
            this.isLoading = false;
          }
        );
      }
    },
    changeTooltipText() {
      const tooltipText = this.$translate(
        this.isVariationInWishList ? "Zur Wunschliste hinzufügen" : "Von Wunschliste entfernen"
      );

      $(".add-to-wish-list").attr("data-original-title", tooltipText).tooltip("hide").tooltip("setContent");
    },
  },
  watch: {
    isVariationInWishList() {
      this.changeTooltipText();
    },
  },
});
