Vue.component("newsletter-form-button", {
  template: "#newsletter-form-button",
  props: {
    formId: String,
  },
  created() {
    window._klOnsite = window._klOnsite || [];
  },
  methods: {
    openForm() {
      window._klOnsite.push(["openForm", this.formId]);
    },
  },
});
