Vue.component("item-variation-box", {
  template: "#vue-item-variation-box",
  props: {
    itemId: [Number, String],
    item: Object,
  },
  data: () => ({
    items: [],
    loading: false,
  }),
  created() {
    this.index();
  },
  methods: {
    index() {
      this.loading = true;
      this.items = [];

      $.get({
        url: "https://ankauf.konsolenkost.de/articleinfo/info_full3.php",
        data: { article_id: this.itemId },
        dataType: "jsonp",
      }).done((response) => {
        if (response !== "undefined") {
          for (let i in response) {
            this.items.push(response[i]);
          }
        }
        this.loading = false;
      });
    },
    formatCurrency(price) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
    formatCondition(fullName, name) {
      let condition;
      if (fullName.substr(0, 13) !== "Merchandise -") {
        condition = fullName;
      } else {
        let matches = fullName.match(/(#[^ ]+|\([^)]+\))/g);
        condition =
          matches && typeof matches === "object" && matches[0]
            ? matches
                .join(", ")
                .replace(", " + name, "")
                .replace(/\(|\)/g, "")
                .replace("#", "")
                .toUpperCase()
            : name;
      }

      return condition;
    },
  },
});
